import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import styled from "styled-components"

import { device, palette } from "../utils/constants"

const PostContent = styled.div`
    width: 100%;
`

const PostContainer = styled.div`
    min-width: 100%;
    transition: 0.2s ease all;
    :hover {
        background-color: ${palette.light.backgroundHighlight};
        transition: 0.2s ease all;
    }
    > a {
        text-decoration: none;
    }

    @media ${device.l} {
        padding-left: 1rem;
        padding-right: 1rem;
    }
`

const PostLinkData = styled.div`
    min-width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
`

const PostLinkTitle = styled.div`
    font-family: 'Zilla Slab', sans-serif;
    font-weight: 500;
    font-size: 16px;
    @media ${device.xs} {
        line-height: 24px;
    }
    @media ${device.m} {
        line-height: 32px;
    }
    color: ${palette.light.primary};
    @media ${device.l} {
        font-size: 21px;
    }
`

const PostLinkMeta = styled.div`
    font-family: 'Fira Sans', sans-serif;
    font-size: 12px;
    font-weight: 300;
    @media ${device.xs} {
        text-align: left;
        line-height: 16px;
    }
    @media ${device.m} {
        text-align: right;
        float: right;
        line-height: 32px;
    }
    @media ${device.l} {
        font-size: 14px;
    }
`

const Index = ({ data }) => {
    return (
    <Layout>
        <title>{data.site.siteMetadata.title}</title>
        <PostContent>
            {data.allMarkdownRemark.edges.map(({ node }) => (
                <PostContainer key={node.id}>
                    <Link to={node.fields.slug}>
                        <PostLinkData>
                            <PostLinkTitle>{node.frontmatter.title}<PostLinkMeta>{node.frontmatter.date}</PostLinkMeta>
                            </PostLinkTitle>

                        </PostLinkData>
                    </Link>
                </PostContainer>
            ))}
        </PostContent>
    </Layout>
    )
}

export default Index

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date(formatString: "DD MMMM, YYYY")
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`
